import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Combines class names using `clsx` and merges them with Tailwind's `twMerge`.
 * @param inputs - Array of class values to merge.
 * @returns A merged class name string.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Calculates the duration since the given date in a human-readable format.
 * @param dateCreated - ISO date string representing the creation date.
 * @returns A string indicating the duration (e.g., "2yrs 3mons", "15days").
 */
export function calculateRegistrationDuration(dateCreated: string): string {
  const createdDate = new Date(dateCreated);
  const now = new Date();

  const timeDifference = now.getTime() - createdDate.getTime();
  const oneDay = 1000 * 60 * 60 * 24;

  if (timeDifference < oneDay) {
    return 'FIRST DAY';
  }

  const days = Math.floor(timeDifference / oneDay);

  if (days < 30) {
    return `${days}day${days > 1 ? 's' : ''}`;
  }

  const months =
    now.getMonth() -
    createdDate.getMonth() +
    (now.getFullYear() - createdDate.getFullYear()) * 12;

  const remainingDays = days % 30;

  if (months < 12) {
    return `${months}mon${months > 1 ? 's' : ''}${
      remainingDays > 0
        ? ` ${remainingDays}day${remainingDays > 1 ? 's' : ''}`
        : ''
    }`;
  }

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  return `${years}yr${years > 1 ? 's' : ''}${
    remainingMonths > 0
      ? ` ${remainingMonths}mon${remainingMonths > 1 ? 's' : ''}`
      : ''
  }`;
}

/**
 * Formats a date string into a detailed date format.
 * @param dateString - ISO date string.
 * @returns A string in the format: "dd MMM yyyy, HH:mm".
 */
export function formatDetailedDate(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  };

  return date.toLocaleDateString(undefined, options);
}

/**
 * Formats a marital status string by replacing underscores with spaces and capitalizing each word.
 * @param status - A string representing marital status (e.g., "single", "married_with_children").
 * @returns A formatted marital status string (e.g., "Single", "Married With Children").
 */
export function formatMaritalStatus(status: string): string {
  return status
    .replace(/_/g, ' ')
    .replace(/\b\w/g, match => match.toUpperCase());
}

/**
 * Formats a birth date string into a human-readable format.
 * @param date - ISO date string representing the birth date.
 * @returns A formatted date string in the format: "MMM dd, yyyy".
 */
export function formatBirthDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

/**
 * Determines if the `special_plus` tag is not present in the given restrictions.
 * @param restrictions - An optional object containing forbidden tags for photos and videos.
 * @returns `true` if `special_plus` is not present, otherwise `false`.
 */
export function hasSpecialPlus(restrictions?: {
  postPhotoForbiddenTags: string[];
  postVideoForbiddenTags: string[];
}): boolean {
  if (!restrictions) return false;
  return !(
    restrictions.postPhotoForbiddenTags.includes('special_plus') ||
    restrictions.postVideoForbiddenTags.includes('special_plus')
  );
}

/**
 * Compares two arrays for equality.
 * @param x - First array.
 * @param y - Second array.
 * @returns `true` if the arrays have the same elements, otherwise `false`.
 */
export function eqArrays<T>(x: T[], y: T[]) {
  if (x.length !== y.length) return false;
  return x.every(x => y.includes(x));
}

/**
 * Generates a toast message indicating that the user has reached the limit for a specific file type.
 * @param type - The type of file ('photo' or 'video').
 * @returns A string message, e.g., "You have reached the photo limit. Remove some photos to add more."
 */
export const getFileLimitToast = (type: 'photo' | 'video'): string =>
  `You have reached the ${type} limit. Remove some ${type}s to add more.`;

/**
 * Generates a toast message indicating that the user has already selected the given file type.
 * @param type - The type of file ('photo' or 'video').
 * @returns A string message, e.g., "You have already selected this photo(s)."
 */
export const getDuplicateToast = (type: 'photo' | 'video'): string =>
  `You have already selected this ${type}(s).`;
