/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'

// Create Virtual Routes

const LoginLazyImport = createFileRoute('/login')()
const AuthenticatedLayoutLazyImport = createFileRoute(
  '/_authenticated/_layout',
)()
const AuthenticatedLayoutSpammerLazyImport = createFileRoute(
  '/_authenticated/_layout/spammer',
)()
const AuthenticatedLayoutSettingsLazyImport = createFileRoute(
  '/_authenticated/_layout/settings',
)()
const AuthenticatedLayoutSocketLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket',
)()
const AuthenticatedLayoutSocketVirtualGiftsLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/virtual-gifts',
)()
const AuthenticatedLayoutSocketMyNewsFeedLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/my-news-feed',
)()
const AuthenticatedLayoutSocketMailLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail',
)()
const AuthenticatedLayoutSocketIcebreakersLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/icebreakers',
)()
const AuthenticatedLayoutSocketChatLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/chat',
)()
const AuthenticatedLayoutSocketBlockedUsersLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/blocked-users',
)()
const AuthenticatedLayoutSocketChatIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/chat/',
)()
const AuthenticatedLayoutSocketMailTrashIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail/trash/',
)()
const AuthenticatedLayoutSocketMailStarredIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail/starred/',
)()
const AuthenticatedLayoutSocketMailOutboxIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail/outbox/',
)()
const AuthenticatedLayoutSocketMailHasLimitsIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail/has-limits/',
)()
const AuthenticatedLayoutSocketChatDialogIdIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/chat/$dialogId/',
)()
const AuthenticatedLayoutSocketChatDialogIdScheduledLazyImport =
  createFileRoute('/_authenticated/_layout/_socket/chat/$dialogId/scheduled')()
const AuthenticatedLayoutSocketMailInboxUnreadIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail/inbox/unread/',
)()
const AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyImport =
  createFileRoute('/_authenticated/_layout/_socket/mail/inbox/unanswered/')()
const AuthenticatedLayoutSocketMailInboxAllIndexLazyImport = createFileRoute(
  '/_authenticated/_layout/_socket/mail/inbox/all/',
)()
const AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyImport =
  createFileRoute('/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/')()
const AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyImport =
  createFileRoute(
    '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled',
  )()

// Create/Update Routes

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedLayoutLazyRoute = AuthenticatedLayoutLazyImport.update({
  id: '/_layout',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() =>
  import('./routes/_authenticated._layout.lazy').then((d) => d.Route),
)

const AuthenticatedLayoutSpammerLazyRoute =
  AuthenticatedLayoutSpammerLazyImport.update({
    id: '/spammer',
    path: '/spammer',
    getParentRoute: () => AuthenticatedLayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/spammer.lazy').then((d) => d.Route),
  )

const AuthenticatedLayoutSettingsLazyRoute =
  AuthenticatedLayoutSettingsLazyImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthenticatedLayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/settings.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketLazyRoute =
  AuthenticatedLayoutSocketLazyImport.update({
    id: '/_socket',
    getParentRoute: () => AuthenticatedLayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket.lazy').then((d) => d.Route),
  )

const AuthenticatedLayoutSocketVirtualGiftsLazyRoute =
  AuthenticatedLayoutSocketVirtualGiftsLazyImport.update({
    id: '/virtual-gifts',
    path: '/virtual-gifts',
    getParentRoute: () => AuthenticatedLayoutSocketLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/virtual-gifts.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketMyNewsFeedLazyRoute =
  AuthenticatedLayoutSocketMyNewsFeedLazyImport.update({
    id: '/my-news-feed',
    path: '/my-news-feed',
    getParentRoute: () => AuthenticatedLayoutSocketLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/my-news-feed.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketMailLazyRoute =
  AuthenticatedLayoutSocketMailLazyImport.update({
    id: '/mail',
    path: '/mail',
    getParentRoute: () => AuthenticatedLayoutSocketLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/mail.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketIcebreakersLazyRoute =
  AuthenticatedLayoutSocketIcebreakersLazyImport.update({
    id: '/icebreakers',
    path: '/icebreakers',
    getParentRoute: () => AuthenticatedLayoutSocketLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/icebreakers.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketChatLazyRoute =
  AuthenticatedLayoutSocketChatLazyImport.update({
    id: '/chat',
    path: '/chat',
    getParentRoute: () => AuthenticatedLayoutSocketLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/chat.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketBlockedUsersLazyRoute =
  AuthenticatedLayoutSocketBlockedUsersLazyImport.update({
    id: '/blocked-users',
    path: '/blocked-users',
    getParentRoute: () => AuthenticatedLayoutSocketLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/blocked-users.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketChatIndexLazyRoute =
  AuthenticatedLayoutSocketChatIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedLayoutSocketChatLazyRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated._layout/_socket/chat/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedLayoutSocketMailTrashIndexLazyRoute =
  AuthenticatedLayoutSocketMailTrashIndexLazyImport.update({
    id: '/trash/',
    path: '/trash/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/trash/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketMailStarredIndexLazyRoute =
  AuthenticatedLayoutSocketMailStarredIndexLazyImport.update({
    id: '/starred/',
    path: '/starred/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/starred/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketMailOutboxIndexLazyRoute =
  AuthenticatedLayoutSocketMailOutboxIndexLazyImport.update({
    id: '/outbox/',
    path: '/outbox/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/outbox/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute =
  AuthenticatedLayoutSocketMailHasLimitsIndexLazyImport.update({
    id: '/has-limits/',
    path: '/has-limits/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/has-limits/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute =
  AuthenticatedLayoutSocketChatDialogIdIndexLazyImport.update({
    id: '/$dialogId/',
    path: '/$dialogId/',
    getParentRoute: () => AuthenticatedLayoutSocketChatLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/chat/$dialogId/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute =
  AuthenticatedLayoutSocketChatDialogIdScheduledLazyImport.update({
    id: '/$dialogId/scheduled',
    path: '/$dialogId/scheduled',
    getParentRoute: () => AuthenticatedLayoutSocketChatLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/chat/$dialogId/scheduled.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute =
  AuthenticatedLayoutSocketMailInboxUnreadIndexLazyImport.update({
    id: '/inbox/unread/',
    path: '/inbox/unread/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/inbox/unread/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute =
  AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyImport.update({
    id: '/inbox/unanswered/',
    path: '/inbox/unanswered/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/inbox/unanswered/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute =
  AuthenticatedLayoutSocketMailInboxAllIndexLazyImport.update({
    id: '/inbox/all/',
    path: '/inbox/all/',
    getParentRoute: () => AuthenticatedLayoutSocketMailLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/mail/inbox/all/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute =
  AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyImport.update({
    id: '/$dialogId/mail-chat/',
    path: '/$dialogId/mail-chat/',
    getParentRoute: () => AuthenticatedLayoutSocketChatLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/chat/$dialogId/mail-chat/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute =
  AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyImport.update({
    id: '/$dialogId/mail-chat/scheduled',
    path: '/$dialogId/mail-chat/scheduled',
    getParentRoute: () => AuthenticatedLayoutSocketChatLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated._layout/_socket/chat/$dialogId/mail-chat/scheduled.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_layout': {
      id: '/_authenticated/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedLayoutLazyImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_layout/_socket': {
      id: '/_authenticated/_layout/_socket'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedLayoutSocketLazyImport
      parentRoute: typeof AuthenticatedLayoutLazyImport
    }
    '/_authenticated/_layout/settings': {
      id: '/_authenticated/_layout/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthenticatedLayoutSettingsLazyImport
      parentRoute: typeof AuthenticatedLayoutLazyImport
    }
    '/_authenticated/_layout/spammer': {
      id: '/_authenticated/_layout/spammer'
      path: '/spammer'
      fullPath: '/spammer'
      preLoaderRoute: typeof AuthenticatedLayoutSpammerLazyImport
      parentRoute: typeof AuthenticatedLayoutLazyImport
    }
    '/_authenticated/_layout/_socket/blocked-users': {
      id: '/_authenticated/_layout/_socket/blocked-users'
      path: '/blocked-users'
      fullPath: '/blocked-users'
      preLoaderRoute: typeof AuthenticatedLayoutSocketBlockedUsersLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketLazyImport
    }
    '/_authenticated/_layout/_socket/chat': {
      id: '/_authenticated/_layout/_socket/chat'
      path: '/chat'
      fullPath: '/chat'
      preLoaderRoute: typeof AuthenticatedLayoutSocketChatLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketLazyImport
    }
    '/_authenticated/_layout/_socket/icebreakers': {
      id: '/_authenticated/_layout/_socket/icebreakers'
      path: '/icebreakers'
      fullPath: '/icebreakers'
      preLoaderRoute: typeof AuthenticatedLayoutSocketIcebreakersLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketLazyImport
    }
    '/_authenticated/_layout/_socket/mail': {
      id: '/_authenticated/_layout/_socket/mail'
      path: '/mail'
      fullPath: '/mail'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketLazyImport
    }
    '/_authenticated/_layout/_socket/my-news-feed': {
      id: '/_authenticated/_layout/_socket/my-news-feed'
      path: '/my-news-feed'
      fullPath: '/my-news-feed'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMyNewsFeedLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketLazyImport
    }
    '/_authenticated/_layout/_socket/virtual-gifts': {
      id: '/_authenticated/_layout/_socket/virtual-gifts'
      path: '/virtual-gifts'
      fullPath: '/virtual-gifts'
      preLoaderRoute: typeof AuthenticatedLayoutSocketVirtualGiftsLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketLazyImport
    }
    '/_authenticated/_layout/_socket/chat/': {
      id: '/_authenticated/_layout/_socket/chat/'
      path: '/'
      fullPath: '/chat/'
      preLoaderRoute: typeof AuthenticatedLayoutSocketChatIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketChatLazyImport
    }
    '/_authenticated/_layout/_socket/chat/$dialogId/scheduled': {
      id: '/_authenticated/_layout/_socket/chat/$dialogId/scheduled'
      path: '/$dialogId/scheduled'
      fullPath: '/chat/$dialogId/scheduled'
      preLoaderRoute: typeof AuthenticatedLayoutSocketChatDialogIdScheduledLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketChatLazyImport
    }
    '/_authenticated/_layout/_socket/chat/$dialogId/': {
      id: '/_authenticated/_layout/_socket/chat/$dialogId/'
      path: '/$dialogId'
      fullPath: '/chat/$dialogId'
      preLoaderRoute: typeof AuthenticatedLayoutSocketChatDialogIdIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketChatLazyImport
    }
    '/_authenticated/_layout/_socket/mail/has-limits/': {
      id: '/_authenticated/_layout/_socket/mail/has-limits/'
      path: '/has-limits'
      fullPath: '/mail/has-limits'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailHasLimitsIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
    '/_authenticated/_layout/_socket/mail/outbox/': {
      id: '/_authenticated/_layout/_socket/mail/outbox/'
      path: '/outbox'
      fullPath: '/mail/outbox'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailOutboxIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
    '/_authenticated/_layout/_socket/mail/starred/': {
      id: '/_authenticated/_layout/_socket/mail/starred/'
      path: '/starred'
      fullPath: '/mail/starred'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailStarredIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
    '/_authenticated/_layout/_socket/mail/trash/': {
      id: '/_authenticated/_layout/_socket/mail/trash/'
      path: '/trash'
      fullPath: '/mail/trash'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailTrashIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
    '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled': {
      id: '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled'
      path: '/$dialogId/mail-chat/scheduled'
      fullPath: '/chat/$dialogId/mail-chat/scheduled'
      preLoaderRoute: typeof AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketChatLazyImport
    }
    '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/': {
      id: '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/'
      path: '/$dialogId/mail-chat'
      fullPath: '/chat/$dialogId/mail-chat'
      preLoaderRoute: typeof AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketChatLazyImport
    }
    '/_authenticated/_layout/_socket/mail/inbox/all/': {
      id: '/_authenticated/_layout/_socket/mail/inbox/all/'
      path: '/inbox/all'
      fullPath: '/mail/inbox/all'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailInboxAllIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
    '/_authenticated/_layout/_socket/mail/inbox/unanswered/': {
      id: '/_authenticated/_layout/_socket/mail/inbox/unanswered/'
      path: '/inbox/unanswered'
      fullPath: '/mail/inbox/unanswered'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
    '/_authenticated/_layout/_socket/mail/inbox/unread/': {
      id: '/_authenticated/_layout/_socket/mail/inbox/unread/'
      path: '/inbox/unread'
      fullPath: '/mail/inbox/unread'
      preLoaderRoute: typeof AuthenticatedLayoutSocketMailInboxUnreadIndexLazyImport
      parentRoute: typeof AuthenticatedLayoutSocketMailLazyImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedLayoutSocketChatLazyRouteChildren {
  AuthenticatedLayoutSocketChatIndexLazyRoute: typeof AuthenticatedLayoutSocketChatIndexLazyRoute
  AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute: typeof AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute
  AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute: typeof AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute
  AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute: typeof AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute
  AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute: typeof AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute
}

const AuthenticatedLayoutSocketChatLazyRouteChildren: AuthenticatedLayoutSocketChatLazyRouteChildren =
  {
    AuthenticatedLayoutSocketChatIndexLazyRoute:
      AuthenticatedLayoutSocketChatIndexLazyRoute,
    AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute:
      AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute,
    AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute:
      AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute,
    AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute:
      AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute,
    AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute:
      AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute,
  }

const AuthenticatedLayoutSocketChatLazyRouteWithChildren =
  AuthenticatedLayoutSocketChatLazyRoute._addFileChildren(
    AuthenticatedLayoutSocketChatLazyRouteChildren,
  )

interface AuthenticatedLayoutSocketMailLazyRouteChildren {
  AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute: typeof AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute
  AuthenticatedLayoutSocketMailOutboxIndexLazyRoute: typeof AuthenticatedLayoutSocketMailOutboxIndexLazyRoute
  AuthenticatedLayoutSocketMailStarredIndexLazyRoute: typeof AuthenticatedLayoutSocketMailStarredIndexLazyRoute
  AuthenticatedLayoutSocketMailTrashIndexLazyRoute: typeof AuthenticatedLayoutSocketMailTrashIndexLazyRoute
  AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute: typeof AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute
  AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute: typeof AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute
  AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute: typeof AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute
}

const AuthenticatedLayoutSocketMailLazyRouteChildren: AuthenticatedLayoutSocketMailLazyRouteChildren =
  {
    AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute:
      AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute,
    AuthenticatedLayoutSocketMailOutboxIndexLazyRoute:
      AuthenticatedLayoutSocketMailOutboxIndexLazyRoute,
    AuthenticatedLayoutSocketMailStarredIndexLazyRoute:
      AuthenticatedLayoutSocketMailStarredIndexLazyRoute,
    AuthenticatedLayoutSocketMailTrashIndexLazyRoute:
      AuthenticatedLayoutSocketMailTrashIndexLazyRoute,
    AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute:
      AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute,
    AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute:
      AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute,
    AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute:
      AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute,
  }

const AuthenticatedLayoutSocketMailLazyRouteWithChildren =
  AuthenticatedLayoutSocketMailLazyRoute._addFileChildren(
    AuthenticatedLayoutSocketMailLazyRouteChildren,
  )

interface AuthenticatedLayoutSocketLazyRouteChildren {
  AuthenticatedLayoutSocketBlockedUsersLazyRoute: typeof AuthenticatedLayoutSocketBlockedUsersLazyRoute
  AuthenticatedLayoutSocketChatLazyRoute: typeof AuthenticatedLayoutSocketChatLazyRouteWithChildren
  AuthenticatedLayoutSocketIcebreakersLazyRoute: typeof AuthenticatedLayoutSocketIcebreakersLazyRoute
  AuthenticatedLayoutSocketMailLazyRoute: typeof AuthenticatedLayoutSocketMailLazyRouteWithChildren
  AuthenticatedLayoutSocketMyNewsFeedLazyRoute: typeof AuthenticatedLayoutSocketMyNewsFeedLazyRoute
  AuthenticatedLayoutSocketVirtualGiftsLazyRoute: typeof AuthenticatedLayoutSocketVirtualGiftsLazyRoute
}

const AuthenticatedLayoutSocketLazyRouteChildren: AuthenticatedLayoutSocketLazyRouteChildren =
  {
    AuthenticatedLayoutSocketBlockedUsersLazyRoute:
      AuthenticatedLayoutSocketBlockedUsersLazyRoute,
    AuthenticatedLayoutSocketChatLazyRoute:
      AuthenticatedLayoutSocketChatLazyRouteWithChildren,
    AuthenticatedLayoutSocketIcebreakersLazyRoute:
      AuthenticatedLayoutSocketIcebreakersLazyRoute,
    AuthenticatedLayoutSocketMailLazyRoute:
      AuthenticatedLayoutSocketMailLazyRouteWithChildren,
    AuthenticatedLayoutSocketMyNewsFeedLazyRoute:
      AuthenticatedLayoutSocketMyNewsFeedLazyRoute,
    AuthenticatedLayoutSocketVirtualGiftsLazyRoute:
      AuthenticatedLayoutSocketVirtualGiftsLazyRoute,
  }

const AuthenticatedLayoutSocketLazyRouteWithChildren =
  AuthenticatedLayoutSocketLazyRoute._addFileChildren(
    AuthenticatedLayoutSocketLazyRouteChildren,
  )

interface AuthenticatedLayoutLazyRouteChildren {
  AuthenticatedLayoutSocketLazyRoute: typeof AuthenticatedLayoutSocketLazyRouteWithChildren
  AuthenticatedLayoutSettingsLazyRoute: typeof AuthenticatedLayoutSettingsLazyRoute
  AuthenticatedLayoutSpammerLazyRoute: typeof AuthenticatedLayoutSpammerLazyRoute
}

const AuthenticatedLayoutLazyRouteChildren: AuthenticatedLayoutLazyRouteChildren =
  {
    AuthenticatedLayoutSocketLazyRoute:
      AuthenticatedLayoutSocketLazyRouteWithChildren,
    AuthenticatedLayoutSettingsLazyRoute: AuthenticatedLayoutSettingsLazyRoute,
    AuthenticatedLayoutSpammerLazyRoute: AuthenticatedLayoutSpammerLazyRoute,
  }

const AuthenticatedLayoutLazyRouteWithChildren =
  AuthenticatedLayoutLazyRoute._addFileChildren(
    AuthenticatedLayoutLazyRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedLayoutLazyRoute: typeof AuthenticatedLayoutLazyRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedLayoutLazyRoute: AuthenticatedLayoutLazyRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedLayoutSocketLazyRouteWithChildren
  '/login': typeof LoginLazyRoute
  '/settings': typeof AuthenticatedLayoutSettingsLazyRoute
  '/spammer': typeof AuthenticatedLayoutSpammerLazyRoute
  '/blocked-users': typeof AuthenticatedLayoutSocketBlockedUsersLazyRoute
  '/chat': typeof AuthenticatedLayoutSocketChatLazyRouteWithChildren
  '/icebreakers': typeof AuthenticatedLayoutSocketIcebreakersLazyRoute
  '/mail': typeof AuthenticatedLayoutSocketMailLazyRouteWithChildren
  '/my-news-feed': typeof AuthenticatedLayoutSocketMyNewsFeedLazyRoute
  '/virtual-gifts': typeof AuthenticatedLayoutSocketVirtualGiftsLazyRoute
  '/chat/': typeof AuthenticatedLayoutSocketChatIndexLazyRoute
  '/chat/$dialogId/scheduled': typeof AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute
  '/chat/$dialogId': typeof AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute
  '/mail/has-limits': typeof AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute
  '/mail/outbox': typeof AuthenticatedLayoutSocketMailOutboxIndexLazyRoute
  '/mail/starred': typeof AuthenticatedLayoutSocketMailStarredIndexLazyRoute
  '/mail/trash': typeof AuthenticatedLayoutSocketMailTrashIndexLazyRoute
  '/chat/$dialogId/mail-chat/scheduled': typeof AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute
  '/chat/$dialogId/mail-chat': typeof AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute
  '/mail/inbox/all': typeof AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute
  '/mail/inbox/unanswered': typeof AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute
  '/mail/inbox/unread': typeof AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedLayoutSocketLazyRouteWithChildren
  '/login': typeof LoginLazyRoute
  '/settings': typeof AuthenticatedLayoutSettingsLazyRoute
  '/spammer': typeof AuthenticatedLayoutSpammerLazyRoute
  '/blocked-users': typeof AuthenticatedLayoutSocketBlockedUsersLazyRoute
  '/icebreakers': typeof AuthenticatedLayoutSocketIcebreakersLazyRoute
  '/mail': typeof AuthenticatedLayoutSocketMailLazyRouteWithChildren
  '/my-news-feed': typeof AuthenticatedLayoutSocketMyNewsFeedLazyRoute
  '/virtual-gifts': typeof AuthenticatedLayoutSocketVirtualGiftsLazyRoute
  '/chat': typeof AuthenticatedLayoutSocketChatIndexLazyRoute
  '/chat/$dialogId/scheduled': typeof AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute
  '/chat/$dialogId': typeof AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute
  '/mail/has-limits': typeof AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute
  '/mail/outbox': typeof AuthenticatedLayoutSocketMailOutboxIndexLazyRoute
  '/mail/starred': typeof AuthenticatedLayoutSocketMailStarredIndexLazyRoute
  '/mail/trash': typeof AuthenticatedLayoutSocketMailTrashIndexLazyRoute
  '/chat/$dialogId/mail-chat/scheduled': typeof AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute
  '/chat/$dialogId/mail-chat': typeof AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute
  '/mail/inbox/all': typeof AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute
  '/mail/inbox/unanswered': typeof AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute
  '/mail/inbox/unread': typeof AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/login': typeof LoginLazyRoute
  '/_authenticated/_layout': typeof AuthenticatedLayoutLazyRouteWithChildren
  '/_authenticated/_layout/_socket': typeof AuthenticatedLayoutSocketLazyRouteWithChildren
  '/_authenticated/_layout/settings': typeof AuthenticatedLayoutSettingsLazyRoute
  '/_authenticated/_layout/spammer': typeof AuthenticatedLayoutSpammerLazyRoute
  '/_authenticated/_layout/_socket/blocked-users': typeof AuthenticatedLayoutSocketBlockedUsersLazyRoute
  '/_authenticated/_layout/_socket/chat': typeof AuthenticatedLayoutSocketChatLazyRouteWithChildren
  '/_authenticated/_layout/_socket/icebreakers': typeof AuthenticatedLayoutSocketIcebreakersLazyRoute
  '/_authenticated/_layout/_socket/mail': typeof AuthenticatedLayoutSocketMailLazyRouteWithChildren
  '/_authenticated/_layout/_socket/my-news-feed': typeof AuthenticatedLayoutSocketMyNewsFeedLazyRoute
  '/_authenticated/_layout/_socket/virtual-gifts': typeof AuthenticatedLayoutSocketVirtualGiftsLazyRoute
  '/_authenticated/_layout/_socket/chat/': typeof AuthenticatedLayoutSocketChatIndexLazyRoute
  '/_authenticated/_layout/_socket/chat/$dialogId/scheduled': typeof AuthenticatedLayoutSocketChatDialogIdScheduledLazyRoute
  '/_authenticated/_layout/_socket/chat/$dialogId/': typeof AuthenticatedLayoutSocketChatDialogIdIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/has-limits/': typeof AuthenticatedLayoutSocketMailHasLimitsIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/outbox/': typeof AuthenticatedLayoutSocketMailOutboxIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/starred/': typeof AuthenticatedLayoutSocketMailStarredIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/trash/': typeof AuthenticatedLayoutSocketMailTrashIndexLazyRoute
  '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled': typeof AuthenticatedLayoutSocketChatDialogIdMailChatScheduledLazyRoute
  '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/': typeof AuthenticatedLayoutSocketChatDialogIdMailChatIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/inbox/all/': typeof AuthenticatedLayoutSocketMailInboxAllIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/inbox/unanswered/': typeof AuthenticatedLayoutSocketMailInboxUnansweredIndexLazyRoute
  '/_authenticated/_layout/_socket/mail/inbox/unread/': typeof AuthenticatedLayoutSocketMailInboxUnreadIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/settings'
    | '/spammer'
    | '/blocked-users'
    | '/chat'
    | '/icebreakers'
    | '/mail'
    | '/my-news-feed'
    | '/virtual-gifts'
    | '/chat/'
    | '/chat/$dialogId/scheduled'
    | '/chat/$dialogId'
    | '/mail/has-limits'
    | '/mail/outbox'
    | '/mail/starred'
    | '/mail/trash'
    | '/chat/$dialogId/mail-chat/scheduled'
    | '/chat/$dialogId/mail-chat'
    | '/mail/inbox/all'
    | '/mail/inbox/unanswered'
    | '/mail/inbox/unread'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/settings'
    | '/spammer'
    | '/blocked-users'
    | '/icebreakers'
    | '/mail'
    | '/my-news-feed'
    | '/virtual-gifts'
    | '/chat'
    | '/chat/$dialogId/scheduled'
    | '/chat/$dialogId'
    | '/mail/has-limits'
    | '/mail/outbox'
    | '/mail/starred'
    | '/mail/trash'
    | '/chat/$dialogId/mail-chat/scheduled'
    | '/chat/$dialogId/mail-chat'
    | '/mail/inbox/all'
    | '/mail/inbox/unanswered'
    | '/mail/inbox/unread'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/login'
    | '/_authenticated/_layout'
    | '/_authenticated/_layout/_socket'
    | '/_authenticated/_layout/settings'
    | '/_authenticated/_layout/spammer'
    | '/_authenticated/_layout/_socket/blocked-users'
    | '/_authenticated/_layout/_socket/chat'
    | '/_authenticated/_layout/_socket/icebreakers'
    | '/_authenticated/_layout/_socket/mail'
    | '/_authenticated/_layout/_socket/my-news-feed'
    | '/_authenticated/_layout/_socket/virtual-gifts'
    | '/_authenticated/_layout/_socket/chat/'
    | '/_authenticated/_layout/_socket/chat/$dialogId/scheduled'
    | '/_authenticated/_layout/_socket/chat/$dialogId/'
    | '/_authenticated/_layout/_socket/mail/has-limits/'
    | '/_authenticated/_layout/_socket/mail/outbox/'
    | '/_authenticated/_layout/_socket/mail/starred/'
    | '/_authenticated/_layout/_socket/mail/trash/'
    | '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled'
    | '/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/'
    | '/_authenticated/_layout/_socket/mail/inbox/all/'
    | '/_authenticated/_layout/_socket/mail/inbox/unanswered/'
    | '/_authenticated/_layout/_socket/mail/inbox/unread/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  LoginLazyRoute: typeof LoginLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LoginLazyRoute: LoginLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_layout"
      ]
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/_authenticated/_layout": {
      "filePath": "_authenticated._layout.lazy.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_layout/_socket",
        "/_authenticated/_layout/settings",
        "/_authenticated/_layout/spammer"
      ]
    },
    "/_authenticated/_layout/_socket": {
      "filePath": "_authenticated._layout/_socket.lazy.tsx",
      "parent": "/_authenticated/_layout",
      "children": [
        "/_authenticated/_layout/_socket/blocked-users",
        "/_authenticated/_layout/_socket/chat",
        "/_authenticated/_layout/_socket/icebreakers",
        "/_authenticated/_layout/_socket/mail",
        "/_authenticated/_layout/_socket/my-news-feed",
        "/_authenticated/_layout/_socket/virtual-gifts"
      ]
    },
    "/_authenticated/_layout/settings": {
      "filePath": "_authenticated._layout/settings.lazy.tsx",
      "parent": "/_authenticated/_layout"
    },
    "/_authenticated/_layout/spammer": {
      "filePath": "_authenticated._layout/spammer.lazy.tsx",
      "parent": "/_authenticated/_layout"
    },
    "/_authenticated/_layout/_socket/blocked-users": {
      "filePath": "_authenticated._layout/_socket/blocked-users.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket"
    },
    "/_authenticated/_layout/_socket/chat": {
      "filePath": "_authenticated._layout/_socket/chat.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket",
      "children": [
        "/_authenticated/_layout/_socket/chat/",
        "/_authenticated/_layout/_socket/chat/$dialogId/scheduled",
        "/_authenticated/_layout/_socket/chat/$dialogId/",
        "/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled",
        "/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/"
      ]
    },
    "/_authenticated/_layout/_socket/icebreakers": {
      "filePath": "_authenticated._layout/_socket/icebreakers.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket"
    },
    "/_authenticated/_layout/_socket/mail": {
      "filePath": "_authenticated._layout/_socket/mail.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket",
      "children": [
        "/_authenticated/_layout/_socket/mail/has-limits/",
        "/_authenticated/_layout/_socket/mail/outbox/",
        "/_authenticated/_layout/_socket/mail/starred/",
        "/_authenticated/_layout/_socket/mail/trash/",
        "/_authenticated/_layout/_socket/mail/inbox/all/",
        "/_authenticated/_layout/_socket/mail/inbox/unanswered/",
        "/_authenticated/_layout/_socket/mail/inbox/unread/"
      ]
    },
    "/_authenticated/_layout/_socket/my-news-feed": {
      "filePath": "_authenticated._layout/_socket/my-news-feed.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket"
    },
    "/_authenticated/_layout/_socket/virtual-gifts": {
      "filePath": "_authenticated._layout/_socket/virtual-gifts.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket"
    },
    "/_authenticated/_layout/_socket/chat/": {
      "filePath": "_authenticated._layout/_socket/chat/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/chat"
    },
    "/_authenticated/_layout/_socket/chat/$dialogId/scheduled": {
      "filePath": "_authenticated._layout/_socket/chat/$dialogId/scheduled.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/chat"
    },
    "/_authenticated/_layout/_socket/chat/$dialogId/": {
      "filePath": "_authenticated._layout/_socket/chat/$dialogId/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/chat"
    },
    "/_authenticated/_layout/_socket/mail/has-limits/": {
      "filePath": "_authenticated._layout/_socket/mail/has-limits/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    },
    "/_authenticated/_layout/_socket/mail/outbox/": {
      "filePath": "_authenticated._layout/_socket/mail/outbox/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    },
    "/_authenticated/_layout/_socket/mail/starred/": {
      "filePath": "_authenticated._layout/_socket/mail/starred/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    },
    "/_authenticated/_layout/_socket/mail/trash/": {
      "filePath": "_authenticated._layout/_socket/mail/trash/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    },
    "/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/scheduled": {
      "filePath": "_authenticated._layout/_socket/chat/$dialogId/mail-chat/scheduled.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/chat"
    },
    "/_authenticated/_layout/_socket/chat/$dialogId/mail-chat/": {
      "filePath": "_authenticated._layout/_socket/chat/$dialogId/mail-chat/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/chat"
    },
    "/_authenticated/_layout/_socket/mail/inbox/all/": {
      "filePath": "_authenticated._layout/_socket/mail/inbox/all/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    },
    "/_authenticated/_layout/_socket/mail/inbox/unanswered/": {
      "filePath": "_authenticated._layout/_socket/mail/inbox/unanswered/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    },
    "/_authenticated/_layout/_socket/mail/inbox/unread/": {
      "filePath": "_authenticated._layout/_socket/mail/inbox/unread/index.lazy.tsx",
      "parent": "/_authenticated/_layout/_socket/mail"
    }
  }
}
ROUTE_MANIFEST_END */
