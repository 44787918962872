import notificationSound from '@/assets/notification.mp3';
import EmailToast from '@/components/emailToast';
import MessageToast from '@/components/messageToast';
import { Button } from '@/components/ui/button';
import { Link } from '@tanstack/react-router';
import { Info } from 'lucide-react';
import toast, { ErrorIcon } from 'react-hot-toast';

const chatIdToToastId = new Map<string, string>();

const audio = new Audio(notificationSound);
audio.volume = 0.7;

const TOAST_DURATION = 10000;

export function newMessageToast(
  idUser: number,
  idInterlocutor: number,
  isTemporary = false,
  background: 'white' | 'orange' | 'green' = 'white'
) {
  const colorMap = {
    white: '#ffffff',
    orange: '#fed7aa',
    green: '#bbf7d0',
  };

  const toastId = toast(
    t => (
      <MessageToast
        idUser={idUser}
        idInterlocutor={idInterlocutor}
        onClose={() => toast.dismiss(t.id)}
      />
    ),
    {
      duration: isTemporary ? TOAST_DURATION : Infinity,
      style: {
        backgroundColor: colorMap[background],
      },
    }
  );

  const chatId = `${idUser}_${idInterlocutor}`;

  const prevToastId = chatIdToToastId.get(chatId);
  if (prevToastId) toast.remove(prevToastId);

  chatIdToToastId.set(chatId, toastId);

  if (!isTemporary) {
    audio.pause();
    audio.currentTime = 0;
    audio.play().catch(e => console.error(e));
  }
}

export function newEmptyMessageToast(
  message: string,
  duration: 'short' | 'long' | 'infinity' = 'long'
) {
  const durationMap = {
    short: 3000,
    long: TOAST_DURATION,
    infinity: Infinity,
  };

  const resolvedDuration = durationMap[duration];

  toast(
    () => (
      <div className='flex items-center gap-2'>
        <Info className='size-4' />
        <span>{message}</span>
      </div>
    ),
    {
      duration: resolvedDuration,
    }
  );
}

export function newEmailToast(idUser: number, idInterlocutor: number) {
  const toastId = toast(
    t => (
      <EmailToast
        id={t.id}
        idUser={idUser}
        idInterlocutor={idInterlocutor}
        onClose={() => toast.dismiss(t.id)}
      />
    ),
    { duration: Infinity }
  );

  const chatId = `email_${idUser}_${idInterlocutor}`;

  const prevToastId = chatIdToToastId.get(chatId);
  if (prevToastId) toast.remove(prevToastId);

  chatIdToToastId.set(chatId, toastId);

  audio.pause();
  audio.currentTime = 0;
  audio.play();
}

export function dismissToast(
  type: 'message' | 'email',
  idUser: number,
  idInterlocutor: number
) {
  const chatId = `${
    type === 'email' ? type + '_' : ''
  }${idUser}_${idInterlocutor}`;
  const toastId = chatIdToToastId.get(chatId);

  if (toastId) {
    toast.dismiss(toastId);
    chatIdToToastId.delete(chatId);
  }
}

export function createGirlLoginErrorToast(email: string) {
  toast(
    t => (
      <div
        className='grid grid-cols-2 grid-rows-2'
        onClick={() => toast.dismiss(t.id)}
      >
        <div>Girl's login failed</div>
        <div className='row-span-2 self-center justify-self-center'>
          <Link to='/settings'>
            <Button variant='destructive'>Settings</Button>
          </Link>
        </div>
        <div className='w-28 truncate text-sm'>{email}</div>
      </div>
    ),
    { duration: Infinity, icon: <ErrorIcon /> }
  );
}
